<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img src='@/assets/images/logo/Apollo.png' height="35px"></b-img>
        <h2 class="brand-text text-primary ml-1">
          Apollo Dry Cleaners
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to Apollo Dry Cleaners! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <!-- <b-alert
              variant="primary"
              show
            >
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
                </p>
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
                </p>
              </div>
              <feather-icon
                v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
                icon="HelpCircleIcon"
                size="18"
                class="position-absolute"
                style="top: 10; right: 10;"
              />
            </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Username" label-for="login-email">
                <validation-provider #default="{ errors }" name="Username" vid="email" rules="required">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="Enter Username" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import util from '@/store/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      index: 0
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      let self = this;
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // useJwt.login({
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     const { userData } = response.data
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(userData))
          //     this.$ability.update(userData.ability)

          //     // ? This is just for demo purpose as well.
          //     // ? Because we are showing eCommerce app's cart items count in navbar
          //     this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: `Welcome ${userData.fullName || userData.username}`,
          //             icon: 'CoffeeIcon',
          //             variant: 'success',
          //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //           },
          //         })
          //       })
          //   })
          //   .catch(error => {
          //     this.$refs.loginForm.setErrors(error.response.data.error)
          //   })

          // useJwt.login({
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     const { userData } = response.data
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(userData))
          //     this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease

          //API CALLED
          var axios = require('axios');
          var data = JSON.stringify({ "username": this.userEmail, "password": this.password });
          var config = {
            method: 'post',
            url: this.$store.state.baseUrl + '/users/authenticate',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              // const ability = [{action: 'manage',subject: 'all',},];
              const ability=util.getAblityByRoles(response.data.roles[0].name)
              
              self.$store.state.ability=ability;
              localStorage.setItem('ability', JSON.stringify(ability));

              self.$ability.update(ability);

              self.$store.state.userData = response.data;
              localStorage.setItem('userData', JSON.stringify(response.data));

              self.$store.state.token = 'Bearer ' + response.data.token;
              let token = 'Bearer ' + response.data.token;
              localStorage.setItem('token', token);

              self.$store.state.userId = response.data.userId;
              localStorage.setItem('userId', response.data.userId);

              self.$store.state.userName = response.data.userName;
              localStorage.setItem('userName', response.data.userName);

              self.$store.state.name = response.data.name;
              localStorage.setItem('name', response.data.name);

              self.$store.state.photo = response.data.photo;
              localStorage.setItem('photo', response.data.photo);

              self.$store.state.isEnebled = response.data.isEnebled;
              localStorage.setItem('isEnebled', response.data.isEnebled);

              self.$store.state.roles = JSON.stringify(response.data.roles);
              localStorage.setItem('roles', JSON.stringify(response.data.roles));

              self.$store.state.empId = response.data.empId;
              localStorage.setItem('empId', response.data.empId);

              self.$store.state.birthday = response.data.birthday;
              localStorage.setItem('birthday', response.data.birthday);

              self.$store.state.anniversary = response.data.anniversary;
              localStorage.setItem('anniversary', response.data.anniversary);

              self.$store.state.storeName = response.data.storeName;
              localStorage.setItem('storeName', response.data.storeName);

              self.$store.state.storeId = response.data.storeId;
              localStorage.setItem('storeId', response.data.storeId);

              self.$store.state.maxDiscountRestricationFlat = response.data.maxDiscountRestricationFlat;
              localStorage.setItem('maxDiscountRestricationFlat', response.data.maxDiscountRestricationFlat),

                self.$store.state.maxDiscountRestricationPersentage = response.data.maxDiscountRestricationPersentage;
              localStorage.setItem('maxDiscountRestricationPersentage', response.data.maxDiscountRestricationPersentage),

                self.$store.state.forcePasswordChange = response.data.forcePasswordChange;
              localStorage.setItem('forcePasswordChange', response.data.forcePasswordChange);

              self.$store.state.defaultConfiguration = JSON.stringify(response.data.defaultConfiguration);
              localStorage.setItem('defaultConfiguration', JSON.stringify(response.data.defaultConfiguration));

              // alert(token);

              self.$router.replace(getHomeRouteForLoggedInUser(response.data.roles[0].name))
                .then(() => {
                  self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${self.$store.state.name || self.$store.state.userName}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${response.data.roles[0].name}. Now you can start to explore!`,
                    },
                  })
                })


            })
            .catch(function (error) {
              console.log(error);
              self.$swal({
                title: '',
                text: "Invalid UserName or Password!",
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            });

          // alert('datammm  :- ' + userData)
          // this.$router.replace(getHomeRouteForLoggedInUser(userData.role[0].name))
          //   .then(() => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       position: 'top-right',
          //       props: {
          //         title: `Welcome ${self.$store.state.name || self.$store.state.userName}`,
          //         icon: 'CoffeeIcon',
          //         variant: 'success',
          //         text: `You have successfully logged in as ${self.$store.state.roles[0].name}. Now you can start to explore!`,
          //       },
          //     })
          //   })
          // })
          // .catch(error => {
          //   this.$refs.loginForm.setErrors(error.response.data.error)
          // })


        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
